import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    packages: [20, 30, 40, 50, 60, 70, 80, 90, 100],
    priceDetails: {},
    continuePriceDetails: {},
    collections: [],
    paymentMethods: [],
    blockedDates: [],
  },
  mutations: {
    //Price Details
    getPriceDetails(state, res) {
      state.priceDetails = res;
    },

    //Continue Price Details
    getContinuePriceDetails(state, res) {
      state.continuePriceDetails = res;
    },

    //Calculate Collections
    calculateCollections(state, data){
      state.packages.map(collection => {
        state.collections.push({
          quantity: collection,
          price: (collection * Number(data.rental_box_price)) + Number(data.extra_price)
        });
      });
    },

    //Payment Methods
    getPaymentMethods(state, res) {
      state.paymentMethods = res;
    },

    //Blocked Dates
    getBlockedDates(state, res) {
      state.blockedDates = res;
    },
  },
  getters: {
    //Return Price Details
    getPriceDetails: (state) => state.priceDetails,

    //Return Continue Price Details
    getContinuePriceDetails: (state) => state.continuePriceDetails,

    //Return Collections
    getCollections: (state) => state.collections,

    //Return Payment Methods
    getPaymentMethods: (state) => state.paymentMethods,

    //Return Blocked Dates
    getBlockedDates: (state) => state.blockedDates,
  },
  actions: {
    //Get Price Details
    async getPriceDetails({ commit }) {
      let data = {
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/wp-json/api/boxes/price-details",
      };
      const res = await axios(data);

      if (res) {
        commit("getPriceDetails", res.data);
        commit("calculateCollections", res.data);
      }
    },

    //Get Price Details
    async getContinuePriceDetails({ commit }) {
      let data = {
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/wp-json/api/boxes/continue-price-details",
      };
      const res = await axios(data);

      if (res) {
        commit("getContinuePriceDetails", res.data);
      }
    },

    //Get Payment Methods
    async getPaymentMethods({ commit }) {
      let data = {
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/wp-json/api/boxes/payment-methods",
      };
      const res = await axios(data);

      if (res) {
        commit("getPaymentMethods", res.data);
      }
    },

    //Get Blocked Dates
    async getBlockedDates({ commit }) {
      let data = {
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/wp-json/api/boxes/blocked-dates",
      };
      const res = await axios(data);

      if (res) {
        commit("getBlockedDates", res.data);
      }
    },
  },
  modules: {},
});
