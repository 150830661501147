<template>
  <router-view/>
</template>

<style>
body {
  overflow-x: hidden;
  width: 100%;
  font-family: "Open Sans"!important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px 0;
  /*max-width: 1000px;*/
  /*margin: 0 auto;*/
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: white;
  z-index: 2;
  border-radius: 6px;
  display: flex;
  align-content: center;
  align-items: center;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #74C92C;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
  margin-top: 30px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#packetsNav .btn-check:active+.btn-outline-primary,
#packetsNav .btn-check:checked+.btn-outline-primary,
#packetsNav .btn-outline-primary.active,
#packetsNav .btn-outline-primary.dropdown-toggle.show,
#packetsNav .btn-outline-primary:active {
  /*color: #fff;*/
  /*background-color: #74C92C;*/
  /*border-color: #74C92C;*/
  min-height: 100px;
}

#packetsNav .btn-outline-primary {
  /*color: #74C92C;*/
  /*border-color: #74C92C;*/
}

#packetsNav .btn-outline-primary:hover {
  /*color: #fff;*/
  /*background-color: #74C92C;*/
  /*border-color: #74C92C;*/
}
#packetsNav .btn-group>.btn-group:not(:last-child)>.btn,
#packetsNav .btn, #packetsNav .btn-group,
#packetsNav .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  outline: none;
}

#packetsNav .btn-group-vertical>.btn-check:checked+.btn,
#packetsNav .btn-group-vertical>.btn-check:focus+.btn,
#packetsNav .btn-group-vertical>.btn.active,
#packetsNav .btn-group-vertical>.btn:active,
#packetsNav .btn-group-vertical>.btn:focus,
#packetsNav .btn-group-vertical>.btn:hover,
#packetsNav .btn-group>.btn-check:checked+.btn,
#packetsNav .btn-group>.btn-check:focus+.btn,
#packetsNav .btn-group>.btn.active,
#packetsNav .btn-group>.btn:active,
#packetsNav .btn-group>.btn:focus,
#packetsNav .btn-group>.btn:hover {
  z-index: 1;
  outline: none;
  box-shadow: none;
}

#packetsNav p {
  font-size: 18px;
}

#packetsNav span {
  min-height: 26px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
}

.mbtn-primary {
  background: #74C92C;
  border-color: #74C92C;
  min-width: 100px;
  box-shadow: none;
}
.mbtn-primary:hover {
  background: #61aa24;
  border-color: #61aa24;
}

.mbtn-outline {
  border-color: #74C92C;
  color: #74C92C;
  min-width: 100px;
  box-shadow: none;
}

.mbtn-outline:hover {
  border-color: #74C92C;
  background: #74C92C;
  color: #ffffff;
}

.error {
  border-color: #f80707 !important;
  border: solid 1px #f80707 !important;
}

.btn-primary {
  background-color: #74C92C!important;
  border-color: #74C92C!important;
}

.btn-check:checked+.btn-outline-primary{
  color: #fff;
  background-color: #74C92C!important;
  border-color: #74C92C!important;
}

.btn-outline-primary {
  border-color: #74C92C!important;
  color: #74C92C;
}
.btn-outline-primary:hover {
  background-color: #74C92C!important;
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: transparent!important;
  outline: none!important;
}

.form-control:focus {
  border-bottom-color: #74C92C;
  box-shadow: transparent!important;
}

</style>
